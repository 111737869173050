<!--
 * @Autor: chenqinglong
 * @Date: 2021-03-19 20:29:26
 * @LastEditors: chenqinglong
 * @LastEditTime: 2021-07-06 15:57:15
 * @Introduce: 
 * @FilePath: \intelligent-performance-vuee:\WWW\workbench-front\src\views\Setting\Set.vue
-->
<template>
  <div class="data-statis">
    <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        text-color="#000"
        router
        active-text-color="#fff"
    >
      <template v-for="menu in dataMenuList">
        <el-menu-item :index="menu.index" :key="menu.index" v-if="menu.noAuth">
          <span>{{menu.title}}</span>
        </el-menu-item>
      </template>
    </el-menu>
    <div class="right-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dataMenuList:[
        {title:"角色权限设置",index:'/home/set/index',noAuth:this.$store.state.listData.role_auth_set,auth:'look_authority'},
        {title:"任务列表",index:'/home/set/asynchronous',noAuth:true,auth:'white-list'},
        {title:"参数设置",index:'/home/set/param',noAuth:true,auth:'set_param'},
        {title:"操作日志",index:'/home/set/operation',noAuth:true,auth:'set_operation'},
      ],
      tabPosition:'left',
    }
  },
  created() {
    this.getIsWx3()
    // this.isUserAuth()
  },
  methods:{
    getIsWx3(){
      let is_wx3=sessionStorage.getItem('is_wx3')==='true';
      console.log(is_wx3)
      this.dataMenuList.map(menu=>{
        if(menu.auth=='set_param'){
          menu.noAuth=!is_wx3;
          console.log(menu.noAuth)
        }
      });
    },
    isUserAuth(){
      const auth=sessionStorage.getItem('userAuth') ? sessionStorage.getItem('userAuth') : sessionStorage.getItem('menuAuth')
      if(auth){
        let authList=JSON.parse(auth);
        authList.map(item=> {
          this.dataMenuList.map(menu=>{
            if(menu.auth==item){
              menu.noAuth=true;
            }
          });
        })
      }

    },
  },
}
</script>

<style lang="less" scoped>
.data-statis{
  display: flex;
  height:100%;
  // width: 100%;
  .el-menu{
    padding-top:10px;
  }
  .el-menu-vertical-demo{
    flex:0 0 190px;
    height:48px;
    border:0;
    /deep/.el-menu-item.is-active{
      background: #66b1ff;
    }

  }
  .right-content{
    flex:1;
    min-height: calc(88vh - 58px);
    border-left:1px solid #D8DFE6;
    //width: 100%;
  }

}
</style>